/* .custom-datepicker-input {
    width: 365px !important;
    padding: 0.2rem;
    font-size: 1rem;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
} */

.custom-datepicker-input-width {
    width: 100% !important;
}

.react-datepicker-wrapper {
    display: block !important;
    width: 100% !important;
    position: relative;
}

.react-datepicker-wrapper .react-datepicker__input-container {
    width: 100% !important;
}


/* switch button */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 19px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2ab934;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    transform: translateX(19px);
}

/*------ ADDED CSS ---------*/
.slider:after {
    content: 'Off';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 12px;
    margin-left: 8px;
    /* margin-top: 0px; */
    font-family: Verdana, sans-serif;
}

input:checked+.slider:after {
    content: 'On';
    font-size: 12px;
    /* font-weight: 700; */
    margin-left: -10px;
    /* margin-top: -0.4px; */
}
input:checked+.slider::before {
    left : 10px;
}

/*--------- END Switch Button --------*/

.purchase .MuiIconButton-root:hover {
    background-color: transparent !important;
}
.purchase .MuiIconButton-root {
    font-size: 0.8rem !important;
}
.mrr-data .card-body {
    padding: 0.5rem 0.5rem !important;
}
.mrr-data th span div {
    margin-left: 11px;
}
.mrr-data .MuiTableSortLabel-icon {
    margin-left: 0px !important;
    margin-right: 0px !important    ;
}