.tran .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #000 !important;
    background-color: #fff !important;
    border-bottom: 2px solid #61A822 !important;
}

.tran .nav-link {
    color: #71757c;
}
.tran__card_1 .card {
    border: 1px solid #ecaa33 !important;
    border-right: 6px solid #e8aba4 !important;
    border-bottom: 2px solid #f1cdc9 !important;
}

.tran__card_2 .card {
    border: 1px solid #f3ca7e !important;
    border-right: 6px solid #73d480 !important;
    border-bottom: 2px solid #85eb93 !important;
}

.tran__card_3 .card {
    border: 1px solid #ecaa33 !important;
    border-right: 6px solid #ab96f7 !important;
    border-bottom: 2px solid #ab96f7 !important;
}
.tran li .nav-link{
    font-weight: 600;
}

.billing_status .card-body{
    padding: 10px 10px;
}
.transaction-email-table table {
    width: 100%;
}
.transaction-email-table table td{
    border: 1px solid #e5e5e5;
    text-align: center;
}
.transaction-invoice {
    display: none;
}
.daily-report {
    display: none;
    margin-bottom: 7px;

}
.daily-sales-report-table table td{
    border: 1px solid #e5e5e5;
    /* text-align: center; */
    padding-left: 2px;
    font-size: 12px;
}
@media print {
    .transaction-invoice {
        display: block;
    }
    .daily-report {
        display: block;
    }
    .daily-sales-report-table{
        margin: 0.5in;
        margin-bottom: 0.5in;
    }
    .daily-sales-report-table table td{
        font-size: 10px;
    }
}
.daily-report {
    font-family: 'Kanit', sans-serif;
}
.daily-sales-report-table table {
    width: 100%;
    
}

.billing_status .dcVXPU > .wrapper {
    font-size: 13px !important;
}
.billing_status .dcVXPU {
    height: 34px !important;
}
.billing_status .jMcrRR {
    min-height: 31px !important;
}

.money-amount-box {
    padding: 5px;
    width: 80px;
    box-shadow: 0px 4.62654px 10.7953px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .money-receipt-head {
    padding: 7px;
    box-shadow: 0px 4.62654px 10.7953px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-weight: 500;
    display: block;
  }
  .payment-invoice-data {
    width: 70px;
    display: inline-block;
  }
  .report-table-wrapper {
    max-height: 420px;
    overflow: hidden;
    overflow-y: auto;
  }
  .report-org-name {
    max-width: 240px;
  }
  .report-org-address {
    max-width: 220px;
  }